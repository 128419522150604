@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  background: #fff;
}

.app {
  position: relative;
  overflow-x: hidden;
}

.loading-div {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

body {
  color: #2d324a;
  line-height: 1.7;
}

span {
  color: #f8d12c;
}

h1 {
  font-weight: 600;
}

textarea {
  font-weight: 100;
  font-size: 2rem;
}

button {
  font-weight: 600;
  font-style: italic;
  font-size: 2.75rem;
}

.navigation {
  background-color: #9c9593;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;
  position: fixed;
  opacity: 0.7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  align-items: center;
  padding: 10px 20px;
  z-index: 300;
}

.navigation__logo-container {
  height: 40px;
  cursor: pointer;
}

.navigation__logo-container img {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.navigation__links-container {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 20px;
}

.navigation__links-container ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.5rem;
  font-weight: 800;
  list-style: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.navigation__links-container li {
  margin-left: 20px;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

.navigation__links-container li:hover {
  color: #2d324a;
}

.navigation__links-container .account-tab {
  color: #2d324a;
  background-color: #f8d12c;
  padding: 3px 10px;
  border-radius: 2px;
}

.navigation__links-container .account-tab:hover {
  letter-spacing: 1.2px;
}

.navigation__hamburger-container {
  color: #fff;
  height: 20px;
  font-weight: 600;
  display: none;
}

.navigation__hamburger-container .navigation__hamburger {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  font-size: 2rem;
}

@media all and (max-width: 991px) {
  .navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .navigation__links-container {
    display: none;
  }
  .navigation__hamburger-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 20px;
  }
  .navigation__hamburger-container .navigation__hamburger {
    font-size: 1.8rem;
    margin-left: auto;
  }
}

.nav-scrolled {
  opacity: 1;
}

.mobile-menu {
  position: absolute;
  right: 0;
  top: 9vh;
  width: 40vw;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background-color: #2d324a;
  z-index: 100;
}

.mobile-menu__links-container {
  color: #fff;
  padding: 10px 20px;
}

.mobile-menu__links-container ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  list-style: none;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mobile-menu__links-container li {
  margin-bottom: 18px;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

.mobile-menu__links-container li:hover {
  color: #2d324a;
}

.mobile-menu__links-container li:last-child {
  color: #2d324a;
  background-color: #f8d12c;
  padding: 3px 10px;
  border-radius: 2px;
}

.hero-section {
  position: relative;
  width: 100vw;
  height: 768px;
  overflow-x: hidden;
}

.hero-section__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -o-object-fit: fill;
     object-fit: fill;
  z-index: -2;
}

.hero-section__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2d324a;
  opacity: 0.5;
  z-index: 1;
}

.hero-section__content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 30vh;
  padding-left: 150px;
  z-index: 2;
}

.hero-section__content h1 {
  font-size: 3.8rem;
  font-weight: 800;
  color: #fff;
  margin-bottom: 20px;
}

.hero-section__content p {
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}

.hero-section__content form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
}

.hero-section__content form input {
  width: 650px;
  height: 45px;
  margin-right: 20px;
  border: 1px solid #2d324a;
  border-bottom: 1px solid #fff;
  background-color: #fff;
  color: #2d324a;
  font-size: 1.8rem;
  font-weight: 600;
  padding-left: 10px;
  border: none;
  border-radius: 5px;
}

.hero-section__content form input:focus {
  outline: none;
}

.hero-section__content form button {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.hero-section__content form button:hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid transparent;
}

.hero-section__content--tasks-preview {
  margin-top: 10px;
  width: 650px;
}

.hero-section__content--tasks-preview ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.hero-section__content--tasks-preview ul li {
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: 600;
  color: #2d324a;
  background-color: #c4c4c4;
  padding: 1px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.hero-section__content--tasks-preview ul li:hover {
  background-color: #2d324a;
  color: #fff;
}

.hero-section__content--search-results {
  width: 650px;
  height: 300px;
  background-color: #fff;
  margin-top: 5px;
  overflow-y: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 10px 15px;
}

.hero-section__content--search-results p {
  color: #2d324a;
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.hero-section__content--search-results p:hover {
  color: #fff;
  background-color: #2d324a;
  padding: 2px 15px;
}

@media all and (max-width: 991px) {
  .hero-section {
    height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .hero-section__background {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .hero-section__content {
    padding-left: 10px;
    padding-bottom: 0;
    width: 100%;
    bottom: 4vh;
  }
  .hero-section__content h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  .hero-section__content p {
    font-size: 1.8rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0.5rem;
  }
  .hero-section__content form {
    margin-top: 1.2rem;
  }
  .hero-section__content form input {
    -webkit-box-flex: 0.8;
        -ms-flex: 0.8;
            flex: 0.8;
    height: 5vh;
    font-size: 1.2rem;
    font-weight: 400;
    padding-right: 10px;
    margin-right: 0.5rem;
  }
  .hero-section__content form button {
    -webkit-box-flex: 0.3;
        -ms-flex: 0.3;
            flex: 0.3;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: 1rem;
    margin-right: 1rem;
    height: 5vh;
  }
  .hero-section__content--tasks-preview {
    margin-top: 0.5rem;
    width: 100%;
  }
  .hero-section__content--tasks-preview ul {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-top: 1rem;
  }
  .hero-section__content--tasks-preview ul li {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 400;
    color: #2d324a;
    background-color: #c4c4c4;
    padding: 1px 10px;
    border-radius: 5px;
    margin-right: 1rem;
    cursor: pointer;
  }
  .hero-section__content--tasks-preview ul li:hover {
    color: #fff;
    background-color: #2d324a;
    padding: 2px 10px;
  }
  .hero-section__content--search-results {
    width: 95%;
    height: 20vh;
    background-color: #fff;
    margin-top: 0.5rem;
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 10px 15px;
  }
  .hero-section__content--search-results p {
    color: #2d324a;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
  }
}

.user-category-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 10px;
}

.user-category-section__CTA:nth-child(1) {
  margin-bottom: 20px;
}

.user-category-section__CTA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.user-category-section__CTA--button {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.user-category-section__CTA--button:hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid #2d324a;
}

.user-category-section__user:nth-child(2) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.user-category-section__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 50%;
  margin-bottom: 50px;
}

.user-category-section__user .user-details {
  -webkit-box-flex: 0.5;
      -ms-flex: 0.5;
          flex: 0.5;
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.user-category-section__user .user-details__benefit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 30px;
  width: 600px;
}

.user-category-section__user .user-details__benefit--icon {
  font-size: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-line-pack: end;
      align-content: flex-end;
  width: 600px;
  padding-left: 10px;
  cursor: pointer;
}

.user-category-section__user .user-details__benefit--icon .symbol {
  color: #f8d12c;
  font-size: 3.4rem;
  margin-right: 20px;
}

.user-category-section__user .user-details__benefit--icon .pointer {
  color: #2d324a;
  margin-left: auto;
}

.user-category-section__user .user-details__benefit--icon h5 {
  font-size: 3rem;
  font-weight: bold;
  color: #2d324a;
  margin-bottom: 10px;
  margin-left: 20px;
}

.user-category-section__user .user-details__benefit--text p {
  font-size: 2.2rem;
  color: #2d324a;
  margin-bottom: 10px;
  margin-left: 80px;
}

.user-category-section__user .user-image {
  -webkit-box-flex: 0.5;
      -ms-flex: 0.5;
          flex: 0.5;
}

.user-category-section__user .user-image img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.user-category-section__user h4 {
  font-size: 3.2rem;
  font-weight: 800;
  color: #2d324a;
  margin-bottom: 30px;
}

@media all and (max-width: 991px) {
  .user-category-section {
    margin-top: 3vh;
  }
  .user-category-section__user {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .user-category-section__user h4 {
    font-size: 2rem;
    font-weight: 800;
    color: #2d324a;
    margin-bottom: 2vh;
  }
  .user-category-section__user .user-details {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 0 0 0 10px;
  }
  .user-category-section__user .user-details h5 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #2d324a;
    margin-bottom: 0.5em;
    margin-left: 0;
  }
  .user-category-section__user .user-details__benefit {
    width: 100%;
  }
  .user-category-section__user .user-details__benefit svg {
    font-size: 1.5rem;
    margin-right: 0;
  }
  .user-category-section__user .user-details__benefit--icon {
    width: inherit;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 11fr 1fr;
        grid-template-columns: 11fr 1fr;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .user-category-section__user .user-details__benefit--icon .symbol {
    display: none;
  }
  .user-category-section__user .user-details__benefit--icon .pointer {
    margin-right: 2.5vh;
  }
  .user-category-section__user .user-details__benefit--text p {
    font-size: 1.5rem;
    color: #2d324a;
    margin-bottom: 0;
    margin-left: 2.3rem;
    margin-right: 1rem;
  }
  .user-category-section__user:nth-child(2) {
    margin-bottom: 2vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .user-category-section .pointer {
    width: 1rem;
  }
  .user-category-section .pointer svg {
    margin-left: auto;
  }
}

.featuredWiz-section {
  height: 650px;
  background-color: #2d324a;
  margin-bottom: 50px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 20px;
}

.featuredWiz-section__title {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media all and (max-width: 991px) {
  .featuredWiz-section__title {
    font-size: 1.5rem;
  }
}

.featuredWiz-section__wizzes-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.featuredWiz-section__CTA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.featuredWiz-section__CTA--button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #f8d12c;
  color: #2d324a;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.featuredWiz-section__CTA--button:hover {
  background-color: #2d324a;
  color: #f8d12c;
  border: thin solid #f8d12c;
}

.featuredWiz-section__CTA--button > svg {
  margin-left: 10px;
  font-weight: 800;
  font-size: 2.5rem;
}

@media all and (max-width: 991px) {
  .featuredWiz-section__CTA--button {
    display: none;
    font-size: 1.5rem;
  }
}

.featuredWiz-section__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 50px;
  margin: 20px 0 50px 0;
  cursor: pointer;
}

.featuredWiz-section__preview--corousel {
  display: none;
}

.featuredWiz-section__preview--item {
  height: 350px;
  width: 20%;
}

.featuredWiz-section__preview--item__image {
  height: 60%;
  width: 100%;
}

.featuredWiz-section__preview--item__image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.featuredWiz-section__preview--item__info {
  height: 40%;
  width: 100%;
  color: #2d324a;
  font-weight: 800;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 8px 8px 10px 8px;
  background-color: #fff;
}

.featuredWiz-section__preview--item__info svg {
  margin-right: 10px;
}

.featuredWiz-section__preview--item__info h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.featuredWiz-section__preview--item__info p {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

@media all and (max-width: 991px) {
  .featuredWiz-section__preview {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .featuredWiz-section__preview::before {
    content: "";
    overflow: hidden;
    z-index: 2;
  }
  .featuredWiz-section__preview--corousel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .featuredWiz-section__preview--item {
    display: none;
    width: 100%;
    height: 100%;
  }
}

.active-wizzes-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100vh;
  background-color: #fff;
  color: #2d324a;
  padding: 20px;
}

@media all and (max-width: 991px) {
  .active-wizzes-view {
    padding: 10px;
  }
}

.popular-skill-section {
  height: 450px;
  margin-top: 20px;
  padding: 10px 50px;
  position: relative;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
}

.popular-skill-section img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
}

.popular-skill-section__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2d324a;
  opacity: 0.5;
  z-index: 2;
}

.popular-skill-section__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 20px 300px;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.popular-skill-section__content svg {
  color: #f8d12c;
}

.popular-skill-section__content h2 {
  color: #fff;
  font-size: 3.3rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.popular-skill-section__content p {
  color: white;
  font-size: 2.1rem;
  font-weight: 600;
  margin-bottom: 20px;
  margin-left: 380px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
}

.popular-skill-section__content p:hover {
  letter-spacing: 1.2px;
  color: #f8d12c;
}

.popular-skill-section__content__skills--title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 70px;
  margin-top: 80px;
}

@media all and (max-width: 768px) {
  .popular-skill-section {
    padding: 10px 20px;
  }
  .popular-skill-section__content {
    padding: 20px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .popular-skill-section__content__skills--title {
    padding-left: 0;
    margin-top: 40px;
  }
  .popular-skill-section__content h2 {
    font-size: 2.5rem;
  }
  .popular-skill-section__content p {
    font-size: 2rem;
    margin-left: 0;
  }
}

.source-talent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.source-talent__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.source-talent__title h2 {
  font-size: 2.5rem;
  font-weight: 900;
  color: #2d324a;
}

.source-talent__title--step-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.source-talent__title--step-label .label-separator {
  width: calc(100% / 3);
  height: 5px;
  background-color: #c4c4c4;
  margin: 0 1rem;
}

.source-talent__title--step-label .label-separator-active {
  background-color: #2d324a;
}

.source-talent__title--step-label .label-entry {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.source-talent__title--step-label .label-entry svg {
  font-size: 3rem;
  color: #fff;
}

.source-talent__title--step-label .label-entry p {
  font-size: 1.4rem;
  font-weight: 700;
  color: #2d324a;
  text-align: center;
}

.source-talent__title--step-label .label-entry__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2vh;
  border-radius: 50%;
  background-color: #2d324a;
}

.source-talent__title--step-label .label-entry-inactive div {
  background-color: #c4c4c4;
}

.source-talent__title--step-label .label-entry-inactive p {
  color: #c4c4c4;
}

.source-talent__title--step-label .label-entry-inactive svg {
  color: #fff;
}

.source-talent__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-top: 2vh;
  padding: 5vh 10vh;
}

.source-talent__content--buttons-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-top: 3vh;
  padding: 0 calc(100% / 6);
}

.source-talent__content--buttons-container button {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.source-talent__content--buttons-container button:hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid #2d324a;
}

.source-talent__content .collect-gig-details {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.source-talent__content .collect-gig-details form {
  width: calc(100% / 2.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.source-talent__content .collect-gig-details form .form-group {
  width: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 3vh;
}

.source-talent__content .collect-gig-details form .form-group .task-category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 500px;
  height: 5vh;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  overflow: hidden;
}

.source-talent__content .collect-gig-details form .form-group .task-category label {
  font-size: 1.4rem;
  font-weight: 900;
  color: #2d324a;
}

.source-talent__content .collect-gig-details form .form-group .task-category select {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 2rem;
}

.source-talent__content .collect-gig-details form .form-group .category-list {
  width: 100%;
}

.source-talent__content .collect-gig-details form .form-group .category-list p {
  color: #2d324a;
  font-size: 1.2rem;
  font-weight: 700;
  width: 500px;
}

.source-talent__content .collect-gig-details form .form-group .label {
  width: 500px;
  font-size: 1.3rem;
  font-weight: 700;
}

.source-talent__content .collect-gig-details form .form-group textarea {
  width: 500px;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  color: #2d324a;
  outline: none;
  padding: 1vh 0 2vh 1vh;
}

.source-talent__content .collect-gig-details form .form-group input {
  width: 500px;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  color: #2d324a;
  outline: none;
  padding: 1vh 0 2vh 1vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.banner {
  height: 600px;
  position: relative;
  margin-bottom: 50px;
}

.banner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2d324a;
  opacity: 0.5;
}

.banner__content {
  position: absolute;
  top: 0;
  padding-top: 20vh;
  width: 100%;
  padding-right: 100px;
  padding-left: 100px;
  text-align: center;
}

.banner__content h1 {
  color: white;
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 20px;
  margin-top: 5vh;
}

.banner__content p {
  color: white;
  font-size: 2.5rem;
  font-weight: 700;
}

.banner__search-section {
  position: absolute;
  bottom: 15vh;
  left: 15%;
  width: 70%;
  padding-right: 50px;
  padding-left: 50px;
  height: 16vh;
  background-color: #2d324a;
  opacity: 0.9;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.banner__search-section input {
  width: 350px;
  height: 45px;
  border: none;
  background-color: #fff;
  color: #2d324a;
  font-size: 1.3rem;
  font-weight: 800;
  padding-left: 15px;
  border: none;
  margin-right: 50px;
  outline: none;
}

.banner__search-section select {
  width: 300px;
  height: 45px;
  border: none;
  background-color: #fff;
  color: #2d324a;
  font-size: 1.5rem;
  font-weight: 700;
  padding-left: 20px;
  padding-right: 10px;
  margin-right: 50px;
  overflow-y: scroll;
}

.banner__search-section select:focus {
  outline: none;
}

.banner__search-section select:focus option:nth-child(1) {
  display: none;
}

.banner__search-section select option:hover {
  background-color: #2d324a;
  color: #fff;
}

.banner__search-section select .select_item {
  color: #2d324a;
  font-weight: 700;
  margin-left: 10px;
  cursor: pointer;
}

.banner__search-section select .select_item:hover {
  background-color: #2d324a;
  color: #fff;
}

.banner__search-section__button-container button {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #f8d12c;
  color: #2d324a;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.banner__search-section__button-container button:hover {
  background-color: #2d324a;
  color: #f8d12c;
  border: thin solid #f8d12c;
}

@media all and (max-width: 991px) {
  .banner {
    padding: 0 2vw 0 2vw;
  }
  .banner__content {
    padding: 20vh 1rem 0 0.5rem;
  }
  .banner__content h1 {
    font-size: 2.8rem;
  }
  .banner__content p {
    font-size: 2rem;
    margin-bottom: 2vh;
  }
  .banner__search-section {
    width: 100vw;
    height: 100px;
    left: 10px;
    padding-right: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0;
    background-color: transparent;
  }
  .banner__search-section input {
    margin-right: 10px;
    width: 90%;
    font-size: 1rem;
    height: 35px;
    margin-bottom: 2vh;
  }
  .banner__search-section select {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 90%;
    font-size: 1rem;
    height: 35px;
  }
}

.about {
  height: 500px;
  margin-bottom: 50px;
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  overflow: hidden;
}

.about__our-story {
  -webkit-box-flex: 0.5;
      -ms-flex: 0.5;
          flex: 0.5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding-left: 50px;
  padding-right: 50px;
}

.about__our-story h2 {
  font-size: 3.8rem;
  font-weight: 900;
  margin-bottom: 10px;
}

.about__our-story p {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 50px;
}

.about__image-container {
  -webkit-box-flex: 0.5;
      -ms-flex: 0.5;
          flex: 0.5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-right: 50px;
}

.about__image-container--image {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media all and (max-width: 991px) {
  .about {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 5vh;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
  }
  .about__our-story {
    padding-left: 2vh;
    padding-right: 2vh;
    margin-bottom: 50px;
  }
  .about__our-story h2 {
    font-size: 2.5rem;
  }
  .about__our-story p {
    font-size: 1.8rem;
    margin-bottom: 2vh;
  }
  .about__image-container {
    padding-left: 2vh;
    padding-right: 2vh;
  }
  .about__image-container--image {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.team {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px 60px;
}

.team h2 {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 20px;
  text-align: center;
}

.team__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 10vh;
  margin-bottom: 50px;
  padding-right: 50px;
  padding-left: 50px;
}

.team__container--corousel {
  display: none;
}

.team__container .member {
  width: 30%;
  margin-bottom: 50px;
  height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  position: relative;
}

.team__container .member__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  opacity: 0.97;
  color: #2d324a;
  background-color: #f8d12c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 20px;
}

.team__container .member__info h3 {
  font-size: 2.3rem;
  font-weight: 800;
}

.team__container .member__info h3 span {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d324a;
  margin-left: 10px;
  font-style: italic;
}

.team__container .member__info p {
  font-size: 1.8rem;
  font-weight: 500;
}

.team__container .member__info a {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 20px;
  color: #2d324a;
  text-decoration: none;
}

.team__container .member img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: 20px;
}

.team__cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: -50px;
  margin-bottom: 100px;
  background-color: #2d324a;
  width: 100%;
  height: 300px;
}

.team__cta p {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 5vh;
}

@media all and (max-width: 991px) {
  .team {
    padding: 2rem;
  }
  .team__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
    margin-top: 2vh;
  }
  .team__container--corousel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .team__container .member {
    display: none;
    width: 100%;
    margin-bottom: 50px;
    height: auto;
  }
  .team h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
}

.team__cta {
  height: auto;
  margin-top: -50px;
  margin-bottom: 100px;
  background-color: #2d324a;
  width: 100%;
  padding: 5vh 2vh;
}

.team__cta p {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 5vh;
}

.team-corousel-section {
  display: none;
}

@media all and (max-width: 768px) {
  .team-corousel-section {
    display: block;
    position: relative;
    width: 300px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    max-height: 400px;
    overflow: hidden;
    padding: 2rem;
  }
  .team-corousel-section__container {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
  }
  .team-corousel-section__container--slide {
    z-index: 1;
    width: 300px;
    height: calc(100% -20vh);
  }
}

.avaiable-wizzes-section {
  color: #2d324a;
  font-size: 1.5rem;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.avaiable-wizzes-section__desktop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 70vw;
  padding: 2vh;
  height: 100%;
  margin: 0;
  border-top: 1px solid #fff;
}

.avaiable-wizzes-section__mobile {
  display: none;
}

.avaiable-wizzes-section h2 {
  font-size: 3.2rem;
  font-weight: 900;
  margin-bottom: 20px;
  text-align: center;
}

.avaiable-wizzes-section__content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 4fr 4fr 4fr;
      grid-template-columns: 4fr 4fr 4fr;
  grid-gap: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.avaiable-wizzes-section__content--item {
  width: 100%;
  border: 1px solid #2d324a;
  -webkit-box-shadow: 5px 10px #888888;
          box-shadow: 5px 10px #888888;
  height: 420px;
  margin-bottom: 20px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.avaiable-wizzes-section__content--item__image {
  width: 120px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 15px;
  margin-left: 15px;
}

.avaiable-wizzes-section__content--item__image img {
  width: 80px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 100%;
}

.avaiable-wizzes-section__content--item__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: calc(100% / 10) calc(100% / 10) 0 15px;
}

.avaiable-wizzes-section__content--item__details h3 {
  font-size: 2rem;
  font-weight: 800;
}

.avaiable-wizzes-section__content--item__details p {
  font-size: 1.8rem;
  font-weight: 700;
  color: #2d90e7;
}

.avaiable-wizzes-section__content--item__details .location {
  color: #2d324a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.avaiable-wizzes-section__content--item__details .action-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  height: 50px;
  margin-top: 30px;
}

.avaiable-wizzes-section__content--item__details .action-buttons .btn {
  margin-right: 10px;
  font-size: 1.2rem;
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #2d324a;
  color: white;
  cursor: pointer;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

.avaiable-wizzes-section__content--item__details .action-buttons .btn:hover {
  background-color: white;
  color: #2d324a;
  border: 1px solid #2d324a;
}

.avaiable-wizzes-section__content--item__details .action-buttons .btn:nth-child(2) {
  margin-left: 15px;
}

.avaiable-wizzes-section__content--item__details .ratings-space {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-bottom: 10px;
  margin-top: 10px;
}

.avaiable-wizzes-section__content--item__details svg {
  margin-right: 15px;
}

.avaiable-wizzes-section__content--item__details .skills ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 10px;
  list-style: none;
}

.avaiable-wizzes-section__content--item__details .skills ul li {
  font-size: 1.5rem;
  font-weight: 700;
  margin-right: calc(100% / 15);
  margin-bottom: 20px;
  padding: 3px 15px;
  background-color: #c4c4c4;
  color: #2d324a;
  border-radius: 5px;
}

.avaiable-wizzes-section__content--item__details .skills ul li:nth-child(4) {
  background-color: transparent;
}

@media all and (max-width: 1000px) {
  .avaiable-wizzes-section__desktop {
    display: none;
  }
  .avaiable-wizzes-section__mobile {
    display: block;
  }
}

.hire-wizes-section {
  color: #2d324a;
  font-size: 1.5rem;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
  height: 700px;
  display: none;
}

.pageNumbers {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  margin-top: 20px;
}

.pageNumbers p {
  margin-right: 15px;
  font-size: 1.8rem;
  font-weight: 700;
}

.pageNumbers li {
  padding: 2px 15px;
  cursor: pointer;
  margin-right: 10px;
  color: #2d324a;
  border-radius: 5px;
  font-size: 1.8rem;
}

.pageNumbers li svg {
  font-size: 2.8rem;
  margin-top: 5px;
  color: #2d324a;
  border: none;
  outline: none;
}

.pageNumbers li button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.pageNumbers li.active {
  background-color: #2d324a;
  color: white;
}

.pageNumbers li button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.pageNumbers li button:hover {
  background-color: white;
  color: #2d324a;
}

.pageNumbers li button:focus {
  outline: none;
}

.wiz-skill-section {
  margin-top: 20px;
  padding: 10px 50px;
  position: relative;
  width: 100%;
  font-size: 1.5rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.wiz-skill-section h1 {
  font-size: 3.3rem;
  font-weight: 500;
  margin-bottom: 2vh;
}

.wiz-skill-section u {
  cursor: pointer;
  font-weight: 800;
}

.wiz-skill-section__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2vh;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.wiz-skill-section__container--image-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.wiz-skill-section__container--image-container img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.wiz-skill-section__container--details-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 2vh 10vh;
}

.wiz-skill-section__container--details-container form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 3vh;
}

.wiz-skill-section__container--details-container form select {
  width: 100%;
  height: 5.3vh;
  margin-bottom: 3vh;
  outline: none;
  border-radius: 5px;
  padding-right: 2vh;
  padding-left: 2vh;
  border: 1px solid #2d324a;
  margin-top: 2vh;
  overflow-y: scroll;
}

.wiz-skill-section__container--details-container form label {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1vh;
}

.wiz-skill-section__container--details-container form button {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.wiz-skill-section__container--details-container form button:hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid #2d324a;
}

.wiz-skill-section__container--details-container form .location-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
  border: 1px solid #2d324a;
  height: 5.3vh;
  border-radius: 5px;
}

.wiz-skill-section__container--details-container form .location-container input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding-left: 3vh;
  margin-left: 1.5vh;
  color: #2d324a;
}

.wiz-skill-section__container--details-container form .location-container svg {
  width: 2.5vh;
  height: 2.5vh;
  margin-left: 1.5vh;
}

@media all and (max-width: 991px) {
  .wiz-skill-section {
    padding: 1vh 2vh;
  }
  .wiz-skill-section h1 {
    font-size: 1.8rem;
    font-weight: 700;
  }
  .wiz-skill-section__container--image-container {
    display: none;
  }
  .wiz-skill-section__container--details-container {
    padding: 2vh;
  }
  .wiz-skill-section__container--details-container form {
    margin-top: 3vh;
  }
  .wiz-skill-section__container--details-container form select {
    width: 100%;
  }
  .wiz-skill-section__container--details-container form .location-container input {
    width: 100%;
  }
}

.account-section {
  position: relative;
  padding-top: 15vh;
  margin: 0;
  width: 100%;
  min-height: 800px;
}

.account-section .auth-page {
  z-index: 20;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.account-section__background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.account-section__background-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.account-section__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2d324a;
  opacity: 0.85;
  z-index: 10;
}

@media screen and (max-width: 991px) {
  .account-section {
    background-color: #fff;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding-top: 5vh;
  }
  .account-section__background-image {
    display: none;
  }
  .account-section__overlay {
    display: none;
  }
}

.auth-page-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: calc(100% / 2.5);
  background-color: #fff;
  position: relative;
  padding: 5vh 10vh;
  border-radius: 0.8vh;
  color: #2d324a;
  font-weight: 700;
  margin-top: 10vh;
}

.auth-page-container h2 {
  font-size: 2.5rem;
  margin-bottom: 1vh;
  text-align: center;
}

.auth-page-container form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2rem;
  width: 100%;
}

.auth-page-container form .form-group {
  all: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
  border: 1px solid #2d324a;
  height: 5.3vh;
  border-radius: 5px;
  width: inherit;
}

.auth-page-container form .form-group input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding-left: 3vh;
  margin-left: 1.5vh;
  color: #2d324a;
}

.auth-page-container form .form-group svg {
  width: 3.5vh;
  height: 3.5vh;
  margin-left: 1.5vh;
}

.auth-page-container form .form-group .show-password {
  width: 3.5vh;
  height: 3.5vh;
  margin-left: -1.5vh;
  margin-right: 2vh;
  cursor: pointer;
}

.auth-page-container form .user-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  margin-top: 2vh;
  height: 5.3vh;
  width: inherit;
}

.auth-page-container form .user-name input {
  height: 100%;
  outline: none;
  border: 1px solid #2d324a;
  border-radius: 5px;
  padding-left: 3vh;
  color: #2d324a;
  width: calc(100% / 2.1);
}

.auth-page-container form .user-name input:nth-child(1) {
  margin-right: 1.5vh;
}

.auth-page-container form .user-name svg {
  display: none;
  width: 3.5vh;
  height: 3.5vh;
  margin-right: 1.5vh;
}

.auth-page-container form button {
  margin-top: 1vh;
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.auth-page-container form button:hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid #2d324a;
}

.auth-page-container__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  font-size: 1.4rem;
  font-weight: 800;
  margin-top: 2vh;
}

.auth-page-container__links p {
  cursor: pointer;
}

.auth-page-container .terms {
  margin-top: 1vh;
  font-size: 1.4rem;
  font-weight: 700;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.auth-page-container .terms input {
  margin-right: 5px;
}

.auth-page-container .terms u {
  cursor: pointer;
  color: green;
}

.auth-page-container .buttons-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2vh;
  width: inherit;
  font-weight: 800;
}

.auth-page-container .buttons-container__cancel {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #f10e0e;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
  margin-right: 1vh;
  width: calc(100% / 2.5);
}

.auth-page-container .buttons-container__cancel:hover {
  background-color: #fff;
  color: #f10e0e;
  border: thin solid #f10e0e;
}

.auth-page-container .buttons-container__submit {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
  width: calc(100% / 2.5);
}

.auth-page-container .buttons-container__submit:hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid #2d324a;
}

.auth-page-container .sign-in {
  text-align: right;
  margin-top: 2.5vh;
  font-size: 1.4rem;
  font-weight: 700;
  border: none;
  margin-bottom: 1vh;
}

.auth-page-container .sign-in u {
  cursor: pointer;
}

@media all and (max-width: 991px) {
  .auth-page-container {
    width: 100%;
    padding: 2vh 2.5vh;
  }
  .auth-page-container__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    font-size: 1.4rem;
    font-weight: 800;
    margin-top: 2vh;
    padding-right: 2vh;
  }
  .auth-page-container__links p {
    cursor: pointer;
  }
  .auth-page-container .terms {
    margin-top: 1vh;
    font-size: 1.2rem;
    font-weight: 600;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
  }
  .auth-page-container .terms input {
    margin-right: 5px;
  }
  .auth-page-container .terms u {
    cursor: pointer;
    color: green;
  }
  .auth-page-container .buttons-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 2vh;
    width: inherit;
    font-weight: 800;
  }
  .auth-page-container .buttons-container__cancel {
    min-width: 200px;
    height: 45px;
    border: none;
    border-radius: 5px;
    padding: 0vh;
    background-color: #f10e0e;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    -webkit-transition: 250ms ease-in-out;
    transition: 250ms ease-in-out;
    cursor: pointer;
    margin-right: 1vh;
    width: calc(100% / 2);
  }
  .auth-page-container .buttons-container__cancel:hover {
    background-color: #fff;
    color: #f10e0e;
    border: thin solid #f10e0e;
  }
  .auth-page-container .buttons-container__submit {
    min-width: 200px;
    height: 45px;
    border: none;
    border-radius: 5px;
    padding: 0vh;
    background-color: #2d324a;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    -webkit-transition: 250ms ease-in-out;
    transition: 250ms ease-in-out;
    cursor: pointer;
    width: calc(100% / 2);
  }
  .auth-page-container .buttons-container__submit:hover {
    background-color: #fff;
    color: #2d324a;
    border: thin solid #2d324a;
  }
  .auth-page-container .sign-in {
    text-align: center;
  }
  .auth-page-container .user-name {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .auth-page-container .user-name input {
    width: 100%;
  }
}

.tool-bar {
  margin-top: 5vh;
  margin-bottom: 2vh;
  height: 1vh;
}

.card-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 2vh;
  margin-bottom: 2vh;
}

.card-layout__paper {
  width: calc(100% / 1.25 - 2vh);
}

.card-layout__paper h3 {
  font-size: 2.4rem;
  text-align: center;
}

.card-layout__stepper {
  color: #2d324a;
}

.card-layout__stepper--label {
  font-size: 2.4rem;
}

.task-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 2vh 0 2vh 5vh;
  padding-left: 2vh;
  width: calc(100% -20vh);
}

.task-details form {
  width: inherit;
  color: #2d324a;
}

.task-details form .form-group {
  margin-bottom: 5vh;
}

.task-details form select {
  width: calc(100% / 1.88);
  border: 1px solid #2d324a;
  padding: 1vh 2vh;
  outline: none;
  border-radius: 2px;
  font-size: 1.2rem;
}

.task-details form textarea {
  height: 70px;
  font-size: 1.2rem;
  padding: 1rem;
  width: calc(100% / 1.88);
  resize: none;
  outline: none;
  border: 1px solid #2d324a;
  margin-top: 1rem;
  border-radius: 5px;
}

.task-details form .date {
  width: calc(100% / 3);
  border: 1px solid #2d324a;
  padding: 1vh 2vh;
  outline: none;
  border-radius: 2px;
  font-size: 1.2rem;
  margin-left: 1rem;
}

.task-details form .location {
  all: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
  border: 1px solid #2d324a;
  height: 5.3vh;
  border-radius: 5px;
  width: calc(100% / 1.88);
}

.task-details form .location input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding-left: 3vh;
  margin-left: 1.5vh;
  font-size: 1.2rem;
  color: #2d324a;
}

.task-details form .location svg {
  width: 3.5vh;
  height: 3.5vh;
  margin-left: 1.5vh;
}

.task-details form .button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-bottom: 2vh;
  width: calc(100% / 1.88);
}

.task-details form .button-container button {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
  width: 25%;
  padding: 0.5vh 2vh;
  height: 35px;
  font-size: 1.24rem;
}

.task-details form .button-container button:hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid #2d324a;
}

.task-details form .button-container .disabled {
  cursor: not-allowed;
  background-color: gray;
}

.task-details form .button-container .disabled:hover {
  background-color: gray;
}

@media all and (max-width: 991px) {
  .task-details {
    all: unset;
  }
  .task-details form {
    width: 100%;
    padding: 1vh 5vh;
    margin: 0;
  }
  .task-details form .form-group {
    width: 100%;
    margin-bottom: 2vh;
  }
  .task-details form .form-group textarea, .task-details form .form-group select, .task-details form .form-group input {
    width: calc(100% - 10vh);
  }
  .task-details form .form-group:nth-child(3) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-line-pack: start;
        align-content: flex-start;
    width: 100%;
  }
  .task-details form .form-group:nth-child(3) .date {
    width: calc(100% / 1.5);
    margin-left: 1rem;
  }
  .task-details form .location {
    width: calc(100% / 1.2);
    margin-left: 1rem;
  }
  .task-details form button {
    display: none;
  }
}

.browse-wizzes-section {
  padding: 5vh 0 0 2vh;
  margin-left: -10vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.8rem;
  position: relative;
}

@media all and (max-width: 991px) {
  .browse-wizzes-section .additonal-wiz-data-btn {
    display: none;
  }
}

.browse-wizzes-section select {
  padding: 1.5vh 4vh;
  border: 1px solid #2d324a;
  outline: none;
  font-size: 1.6rem;
}

.browse-wizzes-section__addtional-wiz-details {
  position: absolute;
  top: -190px;
  left: -10px;
  width: 80vw;
  height: 90vh;
  background-color: #fff;
  z-index: 120;
  border: 1px solid #2d324a;
}

.browse-wizzes-section__filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.browse-wizzes-section__filters .search-wiz {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 350px;
  border: 1px solid #2d324a;
  color: #2d324a;
  padding-right: 2vh;
  border-radius: 5px;
  overflow: hidden;
}

.browse-wizzes-section__filters .search-wiz input {
  height: 100%;
  border: none;
  outline: none;
  padding-left: 2vh;
  width: 98%;
}

@media all and (max-width: 991px) {
  .browse-wizzes-section__filters .search-wiz {
    width: 240px;
    padding: 1vh;
    margin: 1vh 0;
  }
}

.browse-wizzes-section__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 4vh;
  margin-bottom: 2vh;
  width: calc(100% / 1.4);
}

.browse-wizzes-section__buttons button {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.browse-wizzes-section__buttons button:hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid #2d324a;
}

.browse-wizzes-section__buttons .disabled {
  cursor: not-allowed;
  background-color: gray;
}

.browse-wizzes-section__buttons .disabled:hover {
  background-color: gray;
}

.browse-wizzes-section__wizzes-list {
  margin-top: 3vh;
  overflow-y: scroll;
  width: 100%;
  scrollbar-width: 5px;
  scrollbar-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  margin: 0;
}

.browse-wizzes-section__wizzes-list::-webkit-scrollbar {
  width: 5px;
}

.browse-wizzes-section__wizzes-list::-webkit-scrollbar-track {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%, white), to(rgba(63, 15, 64, 0.4)));
  background: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
}

.browse-wizzes-section__wizzes-list::-webkit-scrollbar-thumb {
  background-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  border: none;
  outline: none;
}

@media all and (max-width: 991px) {
  .browse-wizzes-section__wizzes-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }
  .browse-wizzes-section__wizzes-list li p:nth-child(2) {
    -webkit-box-flex: 0.4;
        -ms-flex: 0.4;
            flex: 0.4;
  }
  .browse-wizzes-section__wizzes-list li p:nth-child(6) {
    text-align: right;
  }
  .browse-wizzes-section__wizzes-list li p:nth-child(1),
  .browse-wizzes-section__wizzes-list li p:nth-child(4),
  .browse-wizzes-section__wizzes-list li p:nth-child(5) {
    display: none;
  }
}

.browse-wizzes-section__wizzes-list ul {
  list-style: none;
  width: 100%;
  padding-right: 2vh;
  margin-top: 3vh;
}

.browse-wizzes-section__wizzes-list ul .entry-margin {
  margin-bottom: 2vh;
}

.browse-wizzes-section__wizzes-list ul li:nth-child(even) {
  background-color: #c4c4c4;
  color: white;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

.browse-wizzes-section__wizzes-list ul li:nth-child(even):hover {
  background-color: #fff;
  color: #2d324a;
}

.browse-wizzes-section__wizzes-list ul li {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  -webkit-column-gap: 2vh;
          column-gap: 2vh;
  row-gap: 2vh;
  width: calc(100% -20vh);
  place-content: left;
  border: 1px solid #c4c4c4;
  padding: 1vh 0;
  cursor: pointer;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  font-size: 1.5rem;
  font-weight: 600;
}

.browse-wizzes-section__wizzes-list ul li .person-icon {
  margin-right: 1vh;
  margin-left: 3vh;
}

.browse-wizzes-section__wizzes-list ul li input {
  margin: 0 0 0 1vh;
}

.browse-wizzes-section__wizzes-list ul li .arrows {
  margin-left: auto;
  margin-right: 4vh;
}

.browse-wizzes-section__wizzes-list ul li .rating {
  margin-left: 10vh;
}

@media all and (max-width: 991px) {
  .browse-wizzes-section__wizzes-list ul li li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }
  .browse-wizzes-section__wizzes-list ul li li p:nth-child(2) {
    -webkit-box-flex: 0.4;
        -ms-flex: 0.4;
            flex: 0.4;
  }
  .browse-wizzes-section__wizzes-list ul li li p:nth-child(6) {
    text-align: right;
  }
  .browse-wizzes-section__wizzes-list ul li li p:nth-child(1),
  .browse-wizzes-section__wizzes-list ul li li p:nth-child(4),
  .browse-wizzes-section__wizzes-list ul li li p:nth-child(5) {
    display: none;
  }
}

.browse-wizzes-section__wizzes-list--show-more {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  border: 1px solid #2d324a;
  padding: 1vh 2vh;
  margin-top: 0.5vh;
  margin-bottom: 1vh;
}

.browse-wizzes-section__wizzes-list--show-more__image-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 2vh 0;
}

.browse-wizzes-section__wizzes-list--show-more__image-container img {
  width: 10vh;
  height: 10vh;
  border-radius: 50%;
}

.browse-wizzes-section__wizzes-list--show-more__details {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 2vh 0;
}

.browse-wizzes-section__wizzes-list--show-more__details--left-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-flex: 0.5;
      -ms-flex: 0.5;
          flex: 0.5;
}

.browse-wizzes-section__wizzes-list--show-more__details--left-side div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  width: 100%;
  padding: 0 2vh;
  font-size: 1.6rem;
}

.browse-wizzes-section__wizzes-list--show-more__details--left-side div p {
  margin: 0;
  text-align: left;
}

.browse-wizzes-section__wizzes-list--show-more__details--left-side div p:nth-child(2) {
  margin-left: auto;
  font-weight: 700;
}

.browse-wizzes-section__wizzes-list--show-more__details--right-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-flex: 0.5;
      -ms-flex: 0.5;
          flex: 0.5;
  padding-left: 10vh;
}

.browse-wizzes-section__wizzes-list--show-more__details--right-side div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  width: 100%;
  padding: 0 2vh;
  font-size: 1.6rem;
}

.browse-wizzes-section__wizzes-list--show-more__details--right-side div p {
  margin: 0;
  text-align: left;
}

.browse-wizzes-section__wizzes-list--show-more__details--right-side div p:nth-child(2) {
  margin-left: auto;
  font-weight: 700;
}

.browse-wizzes-section__wizzes-list--show-more__details--right-side div .rates {
  padding: 1vh 2vh;
  font-weight: 600;
  color: #fff;
  background-color: #2d324a;
}

.browse-wizzes-section__wizzes-list--show-more__button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  width: 100%;
  padding: 0 2vh;
  font-size: 1.6rem;
}

.browse-wizzes-section__wizzes-list--show-more__button-container .disabled {
  color: #2d324a;
  background-color: #c4c4c4;
  cursor: not-allowed;
}

.browse-wizzes-section__wizzes-list--show-more__button-container button {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.browse-wizzes-section__wizzes-list--show-more__button-container button:hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid #2d324a;
}

@media all and (max-width: 991px) {
  .browse-wizzes-section__wizzes-list--show-more__button-container {
    margin-top: 2vh;
  }
}

@media all and (max-width: 991px) {
  .browse-wizzes-section {
    all: unset;
    width: 100vw;
  }
  .browse-wizzes-section__filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-line-pack: start;
        align-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding: 0 2vh;
    font-size: 1.6rem;
  }
  .browse-wizzes-section__filters select {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 1vh 0;
  }
  .browse-wizzes-section__wizzes-list {
    all: unset;
    width: calc(100% - 2vh);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-line-pack: start;
        align-content: flex-start;
    overflow-y: scroll;
    height: 35vh;
  }
  .browse-wizzes-section__wizzes-list ul {
    all: unset;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-line-pack: start;
        align-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 5vh;
  }
  .browse-wizzes-section__wizzes-list ul li {
    all: unset;
    width: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    place-items: left;
    padding: 0 1vh;
    font-size: 1.6rem;
    cursor: pointer;
  }
  .browse-wizzes-section__wizzes-list ul li:hover {
    background-color: #2d324a;
    color: #fff;
  }
  .browse-wizzes-section__wizzes-list ul li .person-icon {
    display: none;
  }
  .browse-wizzes-section__wizzes-list ul li p:nth-child(4),
  .browse-wizzes-section__wizzes-list ul li p:nth-child(5) {
    display: none;
  }
}

@media all and (max-width: 991px) and (max-width: 991px) {
  .browse-wizzes-section__wizzes-list ul li {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    place-items: right;
    width: 100%;
  }
  .browse-wizzes-section__wizzes-list ul li p:nth-child(2) {
    -webkit-box-flex: 0.4;
        -ms-flex: 0.4;
            flex: 0.4;
    margin-right: 10px;
  }
  .browse-wizzes-section__wizzes-list ul li p:nth-child(6) {
    text-align: right;
  }
  .browse-wizzes-section__wizzes-list ul li p:nth-child(1),
  .browse-wizzes-section__wizzes-list ul li p:nth-child(4),
  .browse-wizzes-section__wizzes-list ul li p:nth-child(5) {
    display: none;
  }
}

@media all and (max-width: 991px) {
  .browse-wizzes-section__wizzes-list--show-more {
    all: unset;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-line-pack: start;
        align-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 1vh;
    margin-bottom: 3vh;
  }
  .browse-wizzes-section__wizzes-list--show-more__details {
    all: unset;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-line-pack: start;
        align-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 5vh;
  }
  .browse-wizzes-section__wizzes-list--show-more__details--left-side {
    all: unset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }
  .browse-wizzes-section__wizzes-list--show-more__details--left-side div {
    width: 100%;
    padding: 0;
  }
  .browse-wizzes-section__wizzes-list--show-more__details--left-side div:nth-child(3) p:nth-child(2) {
    margin-left: 5vh;
  }
  .browse-wizzes-section__wizzes-list--show-more__details--right-side {
    all: unset;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-line-pack: start;
        align-content: flex-start;
  }
  .browse-wizzes-section__wizzes-list--show-more__details--right-side div {
    width: 100%;
    padding: 0;
  }
  .browse-wizzes-section__button-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    padding: 2vh 0;
  }
}

.gig-assgnment-preview-makepayment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: calc(100% / 2);
  padding: 20px;
  margin-left: 10%;
}

.gig-assgnment-preview-makepayment h1 {
  font-size: 2.1rem;
  font-weight: 800;
  margin-bottom: 10px;
  text-align: center;
}

.gig-assgnment-preview-makepayment__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
}

.gig-assgnment-preview-makepayment__content div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  padding: 0.5vh 2vh;
}

.gig-assgnment-preview-makepayment__content div p:nth-child(even) {
  font-weight: 600;
}

.gig-assgnment-preview-makepayment__content div:nth-child(odd) {
  background-color: #c4c4c4;
  color: #2d324a;
}

.gig-assgnment-preview-makepayment__content--total-amount {
  font-weight: 800;
}

.gig-assgnment-preview-makepayment__content--total-amount p {
  font-weight: 800;
}

.gig-assgnment-preview-makepayment__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 3vh;
}

.gig-assgnment-preview-makepayment__buttons button {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.gig-assgnment-preview-makepayment__buttons button:hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid #2d324a;
}

.gig-assgnment-preview-makepayment__buttons button:nth-child(2) {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 2vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.gig-assgnment-preview-makepayment__buttons button:nth-child(2):hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid #2d324a;
}

@media all and (max-width: 991px) {
  .gig-assgnment-preview-makepayment {
    width: 100%;
    margin-left: 0;
  }
  .gig-assgnment-preview-makepayment__content {
    font-size: 1.4rem;
    width: 100%;
  }
  .gig-assgnment-preview-makepayment__content div {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
  }
}

.hirewiz-component {
  position: relative;
}

@media all and (max-width: 991px) {
  .hirewiz-component__container {
    display: none;
  }
}

.hirewiz-component__mobile-container {
  display: none;
}

@media all and (max-width: 991px) {
  .hirewiz-component__mobile-container {
    display: block;
    padding: 1vh 1.5vh;
  }
}

.hirewiz-component .show-more-wiz-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #2d324a;
  z-index: 2220;
  opacity: 0.95;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.hirewiz-component .show-more-wiz-details__container {
  width: 90%;
  height: 90%;
  background-color: #fff;
}

.hirewiz-component .show-more-wiz-details__container--close-btn {
  padding: 2vh 3vh;
  color: black;
  cursor: pointer;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.hirewiz-component .show-more-wiz-details__container--close-btn svg {
  font-size: 2.5rem;
}

.hirewiz-component .wiz-additonal-details {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.user-profile-section {
  padding: 0;
  margin: 0;
  border: none;
  color: #fff;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  opacity: 0.8;
}

.user-profile-section__mobile-menu {
  position: absolute;
  top: 9.7vh;
  left: 0;
  width: 70vw;
  height: 68vh;
  background-color: #2d324a;
  color: white;
  font-size: 1.8rem;
  font-weight: 800;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 15;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.user-profile-section__mobile-menu--profile-image {
  padding: 2vh 3vh;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #fff;
}

.user-profile-section__mobile-menu--profile-image img {
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  margin-right: 2vh;
}

.user-profile-section__mobile-menu--menu-items {
  padding: 1vh 3vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 2vh;
  width: 100%;
}

.user-profile-section__mobile-menu--menu-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.user-profile-section__mobile-menu--menu-items ul li {
  margin-bottom: 2vh;
  width: 100%;
}

.user-profile-section__mobile-menu--menu-items ul li p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.user-profile-section__mobile-menu--menu-items ul li p span {
  color: #fff;
  margin-left: 1.5vh;
}

.user-profile-section__mobile-menu--menu-items ul li p svg {
  width: 3vh;
  height: 3vh;
}

.user-profile-section__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  font-weight: 700;
  height: 100%;
  width: 100%;
}

.user-profile-section__container--top-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2vh 4vh;
  background-color: #c4c4c4;
  background-image: -webkit-gradient(linear, left top, right top, from(#fff), color-stop(#c4c4c4), color-stop(#2d324a), color-stop(#2d324a), color-stop(#2d324a), to(#2d324a));
  background-image: linear-gradient(to right, #fff, #c4c4c4, #2d324a, #2d324a, #2d324a, #2d324a);
  width: 100%;
}

@media screen and (max-width: 991px) {
  .user-profile-section__container--top-bar {
    display: none;
  }
}

.user-profile-section__container--top-bar__logo {
  cursor: pointer;
}

.user-profile-section__container--top-bar__logo img {
  height: 5vh;
  -o-object-fit: contain;
     object-fit: contain;
}

.user-profile-section__container--top-bar__profile-settings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.8rem;
  font-weight: 700;
}

.user-profile-section__container--top-bar__profile-settings p {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  cursor: pointer;
}

.user-profile-section__container--top-bar__profile-settings p svg {
  height: 2.5vh;
  width: 2.5vh;
  margin-right: 1vh;
}

.user-profile-section__container--top-bar-mobile {
  display: none;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--top-bar-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 2vh 4vh;
    background-color: #c4c4c4;
    width: 100vw;
    background-image: -webkit-gradient(linear, left top, right top, from(#2d324a), color-stop(#c4c4c4), to(#2d324a));
    background-image: linear-gradient(to right, #2d324a, #c4c4c4, #2d324a);
  }
  .user-profile-section__container--top-bar-mobile p svg {
    height: 3.5vh;
    width: 3.5vh;
  }
  .user-profile-section__container--top-bar-mobile__menu svg {
    height: 3.5vh;
    width: 3.5vh;
    margin-right: 1vh;
  }
  .user-profile-section__container--top-bar-mobile__logo {
    cursor: pointer;
  }
  .user-profile-section__container--top-bar-mobile__logo img {
    height: 5vh;
    -o-object-fit: contain;
       object-fit: contain;
  }
}

.user-profile-section__container--main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.user-profile-section__container--main__left-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 15vw;
  background-color: #2d324a;
  height: 90vh;
}

@media screen and (max-width: 991px) {
  .user-profile-section__container--main__left-side {
    display: none;
  }
}

.user-profile-section__container--main__left-side--profile-image {
  padding: 0 2vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 35vh;
  overflow: hidden;
  margin-bottom: 2vh;
  padding-top: 1vh;
}

.user-profile-section__container--main__left-side--profile-image img {
  height: 80%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 5px;
}

.user-profile-section__container--main__left-side--profile-image p {
  font-size: 1.6rem;
  font-weight: 700;
  padding: 0;
  margin-top: 1.5vh;
  color: #fff;
}

.user-profile-section__container--main__left-side--navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  padding: 2vh;
  margin: 0;
  border-top: 1px solid #fff;
}

.user-profile-section__container--main__left-side--navigation ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.user-profile-section__container--main__left-side--navigation ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  margin-bottom: 3vh;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.user-profile-section__container--main__left-side--navigation ul li:hover {
  -webkit-transform: scaleX(1.1);
          transform: scaleX(1.1);
}

.user-profile-section__container--main__left-side--navigation ul li p {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0;
  margin: 0;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.user-profile-section__container--main__left-side--navigation ul li p svg {
  height: 2.6vh;
  width: 2.6vh;
}

.user-profile-section__container--main__left-side--navigation ul li p span {
  color: #fff;
  margin-left: 2vh;
}

.user-profile-section__container--main__left-side--navigation__sign-out {
  margin-top: 2vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 0;
}

.user-profile-section__container--main__left-side--navigation__sign-out p {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0;
  margin: 0;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.user-profile-section__container--main__left-side--navigation__sign-out p svg {
  height: 2.6vh;
  width: 2.6vh;
  margin-right: 1.5vh;
}

.user-profile-section__container--main__right-side {
  width: 85vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0;
  position: relative;
  height: 90vh;
}

@media screen and (max-width: 991px) {
  .user-profile-section__container--main__right-side {
    width: 100vw;
  }
}

.user-profile-section__container--main__right-side--main-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  position: absolute;
  padding: 4vh;
  height: 95%;
  color: #2d324a;
  top: 0;
  left: 0;
}

.user-profile-section__container--main__right-side--main-content__container {
  width: 100%;
  height: 95vh;
  padding: 0;
}

.user-profile-section__container--main__right-side--main-content__container h2 {
  font-size: 3rem;
  font-weight: 800;
}

.user-profile-section__container--main__right-side--main-content__container .dash-board {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .dash-board p {
    margin-left: 1vh;
    font-size: 1rem;
  }
  .user-profile-section__container--main__right-side--main-content__container .dash-board div {
    display: none;
  }
}

.user-profile-section__container--main__right-side--main-content__container .dash-board p {
  margin-left: 2vh;
  font-size: 1.6rem;
  font-weight: 600;
  margin-right: 2vh;
}

.user-profile-section__container--main__right-side--main-content__container .dash-board div {
  height: 3px;
  width: 40%;
  background-color: #c4c4c4;
}

.user-profile-section__container--main__right-side--main-content__container .jobs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  width: 100%;
  height: 100%;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 10vh;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

@media all and (max-width: 820px) {
  .user-profile-section__container--main__right-side--main-content__container .jobs__body {
    all: unset;
  }
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body .gig-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  border: 1px solid #2d324a;
  height: 50vh;
  width: 40vh;
  margin: 0 10vh;
  padding: 4vh 0 0 0;
}

@media all and (max-width: 820px) {
  .user-profile-section__container--main__right-side--main-content__container .jobs__body .gig-container {
    display: none;
  }
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body .gig-container h3 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2vh;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body .gig-container__gig-distributions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 0 5vh;
  margin: 0;
  margin-top: 2vh;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body .gig-container__gig-distributions p {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 2vh;
  margin-top: 10vh;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body .gig-container__browse-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 1vh 2vh;
  height: 8vh;
  border: 1px solid #2d324a;
  border-top: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body .gig-container__browse-button:hover {
  color: #2d324a;
  background-color: #fff;
  border: none;
  border-top: 1px solid #2d324a;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing {
  display: none;
}

@media all and (max-width: 820px) {
  .user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing {
    display: block;
    height: 80vh;
  }
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__my-gigs, .user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__orders {
  width: 45vw;
  border: 1px solid #2d324a;
  padding: 2vh;
  position: relative;
  height: 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__my-gigs h3, .user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__orders h3 {
  font-size: 1.8rem;
  font-weight: 700;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__my-gigs--counters, .user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__orders--counters {
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__my-gigs--counters p, .user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__orders--counters p {
  font-size: 1.5rem;
  font-weight: 800;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__my-gigs--counters p span, .user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__orders--counters p span {
  margin-left: 5vh;
  color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__my-gigs--browse-button, .user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__orders--browse-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  border-radius: 0;
  padding: 1.2vh 0;
  background-color: #2d324a;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__my-gigs--browse-button p, .user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__orders--browse-button p {
  text-align: center;
  font-weight: 800;
  font-size: 1.2rem;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__my-gigs--browse-button:hover, .user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__orders--browse-button:hover {
  background-color: #fff;
  border-top: 1px solid #2d324a;
}

.user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__my-gigs--browse-button:hover p, .user-profile-section__container--main__right-side--main-content__container .jobs__body--mobile-landing__orders--browse-button:hover p {
  color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs,
.user-profile-section__container--main__right-side--main-content__container .my-orders {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 80vh;
  position: relative;
  overflow-y: auto;
  width: 100%;
  padding: 0;
  scrollbar-width: 5px;
  scrollbar-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  margin: 0;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs::-webkit-scrollbar,
.user-profile-section__container--main__right-side--main-content__container .my-orders::-webkit-scrollbar {
  width: 5px;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs::-webkit-scrollbar-track,
.user-profile-section__container--main__right-side--main-content__container .my-orders::-webkit-scrollbar-track {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%, white), to(rgba(63, 15, 64, 0.4)));
  background: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs::-webkit-scrollbar-thumb,
.user-profile-section__container--main__right-side--main-content__container .my-orders::-webkit-scrollbar-thumb {
  background-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  border: none;
  outline: none;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__header,
.user-profile-section__container--main__right-side--main-content__container .my-orders__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__header--back-button,
.user-profile-section__container--main__right-side--main-content__container .my-orders__header--back-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  background-color: #2d324a;
  padding: 1vh 2vh;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  border-radius: 5px;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__header--back-button:hover,
.user-profile-section__container--main__right-side--main-content__container .my-orders__header--back-button:hover {
  color: #2d324a;
  background-color: #fff;
  border: 1px solid #2d324a;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__header--back-button svg,
.user-profile-section__container--main__right-side--main-content__container .my-orders__header--back-button svg {
  margin-right: 1vh;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__header--back-button p,
  .user-profile-section__container--main__right-side--main-content__container .my-orders__header--back-button p {
    display: none;
  }
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__header h2,
.user-profile-section__container--main__right-side--main-content__container .my-orders__header h2 {
  margin-left: calc(100% / 3);
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__header h2,
  .user-profile-section__container--main__right-side--main-content__container .my-orders__header h2 {
    margin-left: calc(100% / 3.5);
  }
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-top: 2vh;
  padding: 0 2vh;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--titles,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--titles {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3fr 3fr 3fr 3fr;
      grid-template-columns: 3fr 3fr 3fr 3fr;
  width: 100%;
  font-size: 1.8rem;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--titles .active-title,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--titles .active-title {
  border: 1px solid #2d324a;
  border-radius: 3px 3px 0 0;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--titles p,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--titles p {
  padding: 5vh 3vh 0 3vh;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--titles p:nth-child(2),
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--titles p:nth-child(2) {
  margin-left: 0.5vh;
  text-align: center;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--titles p:nth-child(3),
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--titles p:nth-child(3) {
  margin-left: 0.5vh;
  text-align: center;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--titles p:last-child,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--titles p:last-child {
  text-align: right;
  padding-right: 5vh;
  margin-left: 0.5vh;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__content--titles,
  .user-profile-section__container--main__right-side--main-content__container .my-orders__content--titles {
    display: none;
  }
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--mobile-tabs,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--mobile-tabs {
  display: none;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__content--mobile-tabs,
  .user-profile-section__container--main__right-side--main-content__container .my-orders__content--mobile-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 1.8vh 0.5vh 0.8vh 1.5vh;
    border-radius: 5vh;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__content--mobile-tabs .tab-entry,
  .user-profile-section__container--main__right-side--main-content__container .my-orders__content--mobile-tabs .tab-entry {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1vh;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #2d324a;
    text-align: center;
    font-weight: 800;
    border: 1px solid #2d324a;
    padding: 1vh 0.5vh 0.8vh 1.8vh;
  }
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__content--mobile-tabs .tab-entry:nth-child(1),
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__content--mobile-tabs .tab-entry:nth-child(2),
  .user-profile-section__container--main__right-side--main-content__container .my-orders__content--mobile-tabs .tab-entry:nth-child(1),
  .user-profile-section__container--main__right-side--main-content__container .my-orders__content--mobile-tabs .tab-entry:nth-child(2) {
    margin-bottom: 0;
  }
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--underline,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--underline {
  width: 100%;
  height: 5px;
  background-color: #2d324a;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__content--underline,
  .user-profile-section__container--main__right-side--main-content__container .my-orders__content--underline {
    height: 0.5vh;
    margin-top: 1vh;
  }
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  margin-top: 2vh;
  font-size: 1.6rem;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 1vh;
  overflow-y: auto;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 2vh;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--data,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--data {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2fr 2.8fr 1fr 0.2fr;
      grid-template-columns: 1fr 2fr 2.8fr 1fr 0.2fr;
  place-items: left;
  width: inherit;
  padding: 1vh 0;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--data p:nth-child(1),
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--data p:nth-child(1) {
  margin-left: 2vh;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--data svg,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--data svg {
  margin-top: 1vh;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--data,
  .user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--data {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1.7fr 1fr 0.3fr;
        grid-template-columns: 1fr 1.7fr 1fr 0.3fr;
    font-size: 1rem;
  }
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--data p:nth-child(3),
  .user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--data p:nth-child(3) {
    display: none;
  }
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--data svg,
  .user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--data svg {
    margin-right: 1vh;
    margin-top: 0.5vh;
  }
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--data p:nth-child(2),
  .user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--data p:nth-child(2) {
    margin-left: 1vh;
  }
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--data p:nth-child(1),
  .user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--data p:nth-child(1) {
    margin-left: 0.6vh;
  }
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element .hide-item-contents,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element .hide-item-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element .hide-item-contents p,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element .hide-item-contents p {
  display: none;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element .hide-item-contents svg,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element .hide-item-contents svg {
  margin-right: 2vh;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 2vh 1vh;
  border: 1px solid #2d324a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background-color: #fff;
  cursor: default;
  position: relative;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__entry,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__entry {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.2fr 1fr;
      grid-template-columns: 0.2fr 1fr;
  width: 100%;
  margin-top: 0.5vh;
  margin-bottom: 1vh;
  font-size: 1.5rem;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__entry p:nth-child(odd),
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__entry p:nth-child(odd) {
  font-weight: 900;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__entry p:nth-child(even),
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__entry p:nth-child(even) {
  text-align: left;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__entry,
  .user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__entry {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 0.8fr 1.2fr;
        grid-template-columns: 0.8fr 1.2fr;
    font-size: 1rem;
    width: 100%;
  }
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__buttons-container,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__buttons-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 1vh;
  padding: 1vh 5vh 0 0;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__buttons-container--button,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__buttons-container--button {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
  margin-left: 10vh;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__buttons-container--button:hover,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__buttons-container--button:hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid #2d324a;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__buttons-container--button:nth-child(2),
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__buttons-container--button:nth-child(2) {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #c4c4c4;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__buttons-container--button:nth-child(2):hover,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__buttons-container--button:nth-child(2):hover {
  background-color: #fff;
  color: #c4c4c4;
  border: thin solid #c4c4c4;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__buttons-container .cancel-job,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__buttons-container .cancel-job {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #f10e0e;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__buttons-container .cancel-job:hover,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__buttons-container .cancel-job:hover {
  background-color: #fff;
  color: #f10e0e;
  border: thin solid #f10e0e;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__buttons-container .mark-as-coplete,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__buttons-container .mark-as-coplete {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #0e7d1f;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__buttons-container .mark-as-coplete:hover,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__buttons-container .mark-as-coplete:hover {
  background-color: #fff;
  color: #0e7d1f;
  border: thin solid #0e7d1f;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__buttons-container .request-cancelation,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__buttons-container .request-cancelation {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 0vh;
  background-color: #c4c4c4;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
  margin-top: -10vh;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__buttons-container .request-cancelation:hover,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__buttons-container .request-cancelation:hover {
  background-color: #fff;
  color: #c4c4c4;
  border: thin solid #c4c4c4;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__chat-container,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__chat-container {
  position: absolute;
  bottom: 50%;
  right: 5vh;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__chat-container:hover,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__chat-container:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__chat-container svg,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__chat-container svg {
  width: 80%;
  height: 50px;
  margin-bottom: -2vh;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element--expanded__chat-container span,
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element--expanded__chat-container span {
  margin-top: -5vh;
  color: #2d324a;
  font-weight: 800;
}

.user-profile-section__container--main__right-side--main-content__container .my-gigs__content--content .entries-container__element:nth-child(odd),
.user-profile-section__container--main__right-side--main-content__container .my-orders__content--content .entries-container__element:nth-child(odd) {
  background-color: #c4c4c4;
}

.user-profile-section__container--main__right-side--main-content__container .hire-wiz__container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 2vh;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .hire-wiz__container {
    display: none;
  }
}

.user-profile-section__container--main__right-side--main-content__container .hire-wiz__mobile-container {
  display: none;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .hire-wiz__mobile-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 2vh;
  }
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section,
.user-profile-section__container--main__right-side--main-content__container .hire-wiz,
.user-profile-section__container--main__right-side--main-content__container .billing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  position: relative;
  overflow-y: auto;
  height: 80vh;
  scrollbar-width: 5px;
  scrollbar-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  margin: 0;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section::-webkit-scrollbar,
.user-profile-section__container--main__right-side--main-content__container .hire-wiz::-webkit-scrollbar,
.user-profile-section__container--main__right-side--main-content__container .billing::-webkit-scrollbar {
  width: 5px;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section::-webkit-scrollbar-track,
.user-profile-section__container--main__right-side--main-content__container .hire-wiz::-webkit-scrollbar-track,
.user-profile-section__container--main__right-side--main-content__container .billing::-webkit-scrollbar-track {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%, white), to(rgba(63, 15, 64, 0.4)));
  background: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section::-webkit-scrollbar-thumb,
.user-profile-section__container--main__right-side--main-content__container .hire-wiz::-webkit-scrollbar-thumb,
.user-profile-section__container--main__right-side--main-content__container .billing::-webkit-scrollbar-thumb {
  background-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  border: none;
  outline: none;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 2vh;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--account-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--account-status h3 {
  font-size: 2.5rem;
  font-weight: 600;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--account-status h4 {
  font-size: 2.2rem;
  font-weight: 700;
  color: #0e7d1f;
  text-align: center;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--account-status button {
  min-width: 200px;
  height: 45px;
  border: none;
  border-radius: 5px;
  padding: 2vh;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  -webkit-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--account-status button:hover {
  background-color: #fff;
  color: #2d324a;
  border: thin solid #2d324a;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--account-status__payout-method {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--account-status__payout-method img {
  width: 80%;
  -o-object-fit: contain;
     object-fit: contain;
  margin-top: -2vh;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--account-status__payout-method p {
  font-size: 1.8rem;
  font-weight: 700;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .billing__body--account-status {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 100%;
  }
  .user-profile-section__container--main__right-side--main-content__container .billing__body--account-status h3 {
    font-size: 1.8rem;
    font-weight: 600;
  }
  .user-profile-section__container--main__right-side--main-content__container .billing__body--account-status h4 {
    font-size: 1.6rem;
    font-weight: 700;
    color: #0e7d1f;
    text-align: center;
  }
  .user-profile-section__container--main__right-side--main-content__container .billing__body--account-status button {
    display: none;
    min-width: 200px;
    height: 45px;
    border: none;
    border-radius: 5px;
    padding: 2vh;
    background-color: #2d324a;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    -webkit-transition: 250ms ease-in-out;
    transition: 250ms ease-in-out;
    cursor: pointer;
  }
  .user-profile-section__container--main__right-side--main-content__container .billing__body--account-status button:hover {
    background-color: #fff;
    color: #2d324a;
    border: thin solid #2d324a;
  }
  .user-profile-section__container--main__right-side--main-content__container .billing__body--account-status__payout-method {
    margin-top: 2vh;
  }
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 2vh 0;
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0;
  margin: 0;
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-header h3 {
  font-size: 2.1rem;
  font-weight: 700;
  margin-bottom: 1vh;
  color: #c4c4c4;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-header div {
  width: calc(100% / 1.3);
  height: 0.5vh;
  background-color: #c4c4c4;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-header {
    width: 100%;
  }
  .user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-header h3 {
    font-size: 1.8rem;
  }
  .user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-header div {
    width: calc(100% / 3.3);
  }
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-body {
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-body__item {
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-body__item--no-data {
  color: #c4c4c4;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 2vh 0;
  margin-left: 2vh;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-body__item--entry {
  width: 100%;
  padding: 3vh 2vh;
  border: 0.5px solid #c4c4c4;
  height: 5vh;
  border-radius: 2vh;
  -webkit-box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1vh;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-body__item--entry p:nth-child(1) {
  color: #060e58;
  font-weight: 800;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-body__item--entry:hover {
  background-color: #2d324a;
  color: #c4c4c4;
}

.user-profile-section__container--main__right-side--main-content__container .billing__body--billing-history__group--category-body__item--entry:hover p:nth-child(1) {
  color: #c4c4c4;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section {
  width: 100%;
  padding: 2vh 0;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--header__back-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.8rem;
  font-weight: 800;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border: thin solid #c4c4c4;
  padding: 0.6vh 2vh;
  color: #fff;
  background-color: #2d324a;
  border-radius: 1vh;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--header__back-btn:hover {
  background-color: #c4c4c4;
  color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--header__back-btn svg {
  margin-right: 1vh;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--header__back-btn p {
    display: none;
  }
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body {
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info {
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info .underline {
  width: 100%;
  height: 0.5vh;
  background-color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info .total-bill-amount {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.8rem;
  font-weight: 700;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info .total-bill-amount p:nth-child(2) {
  color: #060e58;
  font-weight: 900;
  margin-left: 10vh;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-header {
  width: 100%;
  margin-top: 2vh;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-header .bill-info-header-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10fr 1fr;
      grid-template-columns: 1fr 10fr 1fr;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-header .bill-info-header-content p {
  font-size: 1.8rem;
  font-weight: 800;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-header .bill-info-header-content {
    -ms-grid-columns: 0.6fr 2.4fr 1fr;
        grid-template-columns: 0.6fr 2.4fr 1fr;
  }
  .user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-header .bill-info-header-content p {
    font-size: 1.4rem;
    font-weight: 800;
  }
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body {
  width: 100%;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 9.8fr 1.2fr;
      grid-template-columns: 1fr 9.8fr 1.2fr;
  margin-bottom: 1vh;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content p {
  font-size: 1.4rem;
  font-weight: 600;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content p span {
  color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content p:nth-child(1) {
  margin-left: 1.5vh;
}

.user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content:nth-child(even) {
  background-color: #c4c4c4;
  color: #2d324a;
  font-weight: 700;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content {
    -ms-grid-columns: 0.6fr 2.2fr 1.2fr;
        grid-template-columns: 0.6fr 2.2fr 1.2fr;
  }
  .user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content p {
    font-size: 1.4rem;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content p span {
    display: none;
  }
  .user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content p span:nth-child(2),
  .user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content p span:nth-child(4) {
    display: block;
  }
  .user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content p span:nth-child(2) {
    margin: 0 1.5vh 0 0;
  }
  .user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content p:nth-child(1) {
    margin-left: 1.5vh;
  }
  .user-profile-section__container--main__right-side--main-content__container .bill-detail-section__container--body__bill-info--bill-info-body .bill-info-body-content p:last-child {
    text-align: right;
  }
}

.user-profile-section__container--main__right-side--main-content .profile-settings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 80vh;
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2.1rem;
  font-weight: 800;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__tabs p {
  padding: 1.5rem 5rem 0 5rem;
  font-size: 2.4rem;
  font-weight: 700;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__tabs--active-tab {
  border: 1px solid #2d324a;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__underline {
  width: 100%;
  height: 0.8vh;
  background-color: #2d324a;
  margin-bottom: 1.5vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account, .user-profile-section__container--main__right-side--main-content .profile-settings__skills, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview {
  height: 80vh;
  overflow-y: auto;
  scrollbar-width: 5px;
  scrollbar-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account::-webkit-scrollbar, .user-profile-section__container--main__right-side--main-content .profile-settings__skills::-webkit-scrollbar, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview::-webkit-scrollbar {
  width: 5px;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account::-webkit-scrollbar-track, .user-profile-section__container--main__right-side--main-content .profile-settings__skills::-webkit-scrollbar-track, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview::-webkit-scrollbar-track {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%, white), to(rgba(63, 15, 64, 0.4)));
  background: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account::-webkit-scrollbar-thumb, .user-profile-section__container--main__right-side--main-content .profile-settings__skills::-webkit-scrollbar-thumb, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview::-webkit-scrollbar-thumb {
  background-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  border: none;
  outline: none;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--section-title, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--section-title, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--section-title {
  font-weight: 700;
  margin-bottom: 1rem;
  color: #c4c4c4;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--section-title h3, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--section-title h3, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--section-title h3 {
  font-size: 1.8rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--section-title div, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--section-title div, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--section-title div {
  margin-left: 1.5rem;
  height: 0.4rem;
  background-color: #c4c4c4;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.6rem;
  width: 100%;
  margin-bottom: 1.8vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info span, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info span, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info span {
  color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info p:nth-child(1), .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info p:nth-child(1), .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info p:nth-child(1) {
  width: calc(100% / 4);
  color: #636675;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info div, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info div, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(100% / 1.5);
  color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .faint-bg, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .faint-bg, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .faint-bg {
  background-color: #c4c4c4;
  padding: 0.8vh 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .details-capture, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .details-capture, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .details-capture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: calc(100% / 1.5);
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .details-capture input, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .details-capture input, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .details-capture input {
  width: 45%;
  border: 0.5px solid #c4c4c4;
  color: #2d324a;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 1vh 0.5rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .details-capture input:focus, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .details-capture input:focus, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .details-capture input:focus {
  outline: none;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .address, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .address, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: calc(100% / 1.5);
  border: 0.5px solid #c4c4c4;
  padding: 0.3rem 0 0.3rem 1rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .address svg, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .address svg, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .address svg {
  margin-right: 1rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .address input, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .address input, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .address input {
  width: 45%;
  color: #2d324a;
  font-size: 1.4rem;
  border: none;
  font-weight: 700;
  padding: 1vh 0.5rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .address input:focus, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .address input:focus, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .address input:focus {
  outline: none;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .gender, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .gender, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .gender {
  all: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(100% / 1.5);
  position: relative;
  margin-bottom: 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .gender p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .gender p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .gender p {
  color: #fff;
  background-color: #2d324a;
  -webkit-box-flex: 0.93;
      -ms-flex: 0.93;
          flex: 0.93;
  padding-left: 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .gender div, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .gender div, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .gender div {
  -webkit-box-flex: 0.07;
      -ms-flex: 0.07;
          flex: 0.07;
  margin-left: 0.5rem;
  background-color: #2d324a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.85vh 0;
  font-weight: 800;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .gender div svg, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .gender div svg, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .gender div svg {
  color: #fff;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .skill-approval-pending,
.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .skill-approval-approved, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .skill-approval-pending,
.user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .skill-approval-approved, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .skill-approval-pending,
.user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .skill-approval-approved {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0.3vh 4vh;
  font-size: 1.6rem;
  font-weight: 800;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0.5vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .skill-approval-pending, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .skill-approval-pending, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .skill-approval-pending {
  background-color: #c4c4c4;
  border: 0.5px solid #c4c4c4;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .skill-approval-pending p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .skill-approval-pending p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .skill-approval-pending p {
  color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .skill-approval-approved, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .skill-approval-approved, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .skill-approval-approved {
  background-color: #0e7d1f;
  border: 0.5px solid #c4c4c4;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .skill-approval-approved p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .skill-approval-approved p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .skill-approval-approved p {
  color: #fff;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .skill-distriution, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .skill-distriution, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .skill-distriution {
  all: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(100% / 2.5);
  position: relative;
  margin-bottom: 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .skill-distriution p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .skill-distriution p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .skill-distriution p {
  color: #fff;
  background-color: #2d324a;
  -webkit-box-flex: 0.93;
      -ms-flex: 0.93;
          flex: 0.93;
  padding-left: 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .skill-distriution div, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .skill-distriution div, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .skill-distriution div {
  -webkit-box-flex: 0.07;
      -ms-flex: 0.07;
          flex: 0.07;
  margin-left: 0.5rem;
  background-color: #2d324a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.85vh 0;
  font-weight: 800;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .skill-distriution div svg, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .skill-distriution div svg, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .skill-distriution div svg {
  color: #fff;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-settings-title, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-settings-title, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-settings-title {
  margin-bottom: 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-settings-title p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-settings-title p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-settings-title p {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 1.3rem;
  font-weight: 800;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-settings-title p input, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-settings-title p input, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-settings-title p input {
  margin-left: 2vh;
  height: 3vh;
  width: 3vh;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-settings-title p span, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-settings-title p span, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-settings-title p span {
  margin-left: 2vh;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-settings-title p svg, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-settings-title p svg, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-settings-title p svg {
  height: 3vh;
  width: 3vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-settings-title p span:nth-child(2),
.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-settings-title p span:nth-child(3), .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-settings-title p span:nth-child(2),
.user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-settings-title p span:nth-child(3), .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-settings-title p span:nth-child(2),
.user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-settings-title p span:nth-child(3) {
  all: unset;
  margin-left: 1vh;
  margin-right: 1vh;
  width: 3vh;
  height: 2px;
  background-color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info__date-range-picker, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info__date-range-picker, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info__date-range-picker {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 2vh auto;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info__date-range-picker span, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info__date-range-picker span, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info__date-range-picker span {
  color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info__buttons-container, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info__buttons-container, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info__buttons-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 5vh;
  width: 100%;
  background-color: white;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info__buttons-container button, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info__buttons-container button, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info__buttons-container button {
  margin-right: 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .documents-upload-container, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .documents-upload-container, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .documents-upload-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: calc(100% / 1);
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-size: 1.7rem;
  font-weight: 800;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .documents-upload-container p span, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .documents-upload-container p span, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .documents-upload-container p span {
  color: #4085ee;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .documents-upload-container__upload, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .documents-upload-container__upload, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .documents-upload-container__upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .documents-upload-container__upload--entry, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .documents-upload-container__upload--entry, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .documents-upload-container__upload--entry {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 2vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .documents-upload-container__upload--entry div, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .documents-upload-container__upload--entry div, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .documents-upload-container__upload--entry div {
  width: 80%;
  padding: 0.3vh 2vh;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: #2d324a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 0.5vh;
  margin-right: 3vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .documents-upload-container__upload--entry div p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .documents-upload-container__upload--entry div p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .documents-upload-container__upload--entry div p {
  color: #fff;
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .change-password, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .change-password, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .change-password {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-size: 1.7rem;
  font-weight: 800;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .change-password p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .change-password p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .change-password p {
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .change-password__button, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .change-password__button, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .change-password__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: #fff;
  background-color: #2d324a;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  cursor: pointer;
  padding: 1vh 3vh;
  border-radius: 0.8vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .change-password__button svg, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .change-password__button svg, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .change-password__button svg {
  width: 3.5vh;
  height: 3.5vh;
  margin-right: 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .change-password__button:hover, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .change-password__button:hover, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .change-password__button:hover {
  color: #2d324a;
  background-color: #fff;
  border: 1px solid #2d324a;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .delete-account, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .delete-account, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .delete-account {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-size: 1.7rem;
  margin-left: 5vh;
  font-weight: 800;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .delete-account p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .delete-account p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .delete-account p {
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .delete-account__button, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .delete-account__button, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .delete-account__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: #fff;
  background-color: #c4c4c4;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  cursor: pointer;
  padding: 1vh 3vh;
  border-radius: 0.8vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .delete-account__button svg, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .delete-account__button svg, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .delete-account__button svg {
  width: 3.5vh;
  height: 3.5vh;
  margin-right: 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .delete-account__button:hover, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .delete-account__button:hover, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .delete-account__button:hover {
  color: #2d324a;
  background-color: #fff;
  border: 1px solid #2d324a;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications p {
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications__channels-conatiner, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications__channels-conatiner, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications__channels-conatiner {
  width: 100%;
  margin-left: 5vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications__channels-conatiner--title, .user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications__channels-conatiner--entry, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications__channels-conatiner--title, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications__channels-conatiner--entry, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications__channels-conatiner--title, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications__channels-conatiner--entry {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications__channels-conatiner--title p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications__channels-conatiner--title p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications__channels-conatiner--title p {
  font-size: 1.8rem;
  font-weight: 900;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications__channels-conatiner--entry__timing p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications__channels-conatiner--entry__timing p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications__channels-conatiner--entry__timing p {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  width: calc(100% / 3);
  background-color: #2d324a;
  padding: 0.2vh 2vh;
  border-radius: 0.8vh 0 0 0.8vh;
  margin-right: 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications__channels-conatiner--entry__timing--selector, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications__channels-conatiner--entry__timing--selector, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications__channels-conatiner--entry__timing--selector {
  width: calc(100% / 8);
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications__channels-conatiner--entry__timing--selector select, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications__channels-conatiner--entry__timing--selector select, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications__channels-conatiner--entry__timing--selector select {
  width: 100%;
  background-color: #2d324a;
  height: 4.5vh;
  border-radius: 0 0.8vh 0.8vh 0;
  padding: 0.2vh 2vh;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
  border: none;
  outline: none;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications__channels-conatiner--entry__toogle, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications__channels-conatiner--entry__toogle, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications__channels-conatiner--entry__toogle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications__channels-conatiner--entry__toogle div, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications__channels-conatiner--entry__toogle div, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications__channels-conatiner--entry__toogle div {
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  width: calc(100% / 4);
  background-color: #2d324a;
  padding: 0.2vh 2vh;
  border-radius: 2vh;
  margin-right: 1vh;
  height: 4.5vh;
  position: relative;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications__channels-conatiner--entry__toogle div p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications__channels-conatiner--entry__toogle div p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications__channels-conatiner--entry__toogle div p {
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications__channels-conatiner--entry__toogle div .inactive-toogle, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications__channels-conatiner--entry__toogle div .inactive-toogle, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications__channels-conatiner--entry__toogle div .inactive-toogle {
  position: absolute;
  left: 0.5vh;
  width: 3.8vh;
  height: 3.8vh;
  border-radius: 50%;
  background-color: #fff;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .notifications__channels-conatiner--entry__toogle div .active-toogle, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .notifications__channels-conatiner--entry__toogle div .active-toogle, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .notifications__channels-conatiner--entry__toogle div .active-toogle {
  position: absolute;
  right: 0.5vh;
  width: 3.8vh;
  height: 3.8vh;
  border-radius: 50%;
  background-color: #fff;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .payout-method, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .payout-method, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .payout-method {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(100% / 1.5);
  height: 7vh;
  margin-top: 0;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .payout-method img, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .payout-method img, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .payout-method img {
  height: 7vh;
  -o-object-fit: contain;
     object-fit: contain;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .mobile-number, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .mobile-number, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .mobile-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(100% / 1.5);
  border: 0.5px solid #c4c4c4;
  padding: 0.3rem 0 0.3rem 1rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .mobile-number svg, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .mobile-number svg, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .mobile-number svg {
  margin-right: 1rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .mobile-number input, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .mobile-number input, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .mobile-number input {
  letter-spacing: 0.2rem;
  width: 45%;
  color: #2d324a;
  font-size: 1.4rem;
  border: none;
  font-weight: 700;
  padding: 1vh 0.5rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .mobile-number input:focus, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .mobile-number input:focus, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .mobile-number input:focus {
  outline: none;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth {
  all: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(100% / 1.5);
  margin-bottom: 1rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  border-radius: 0.5 0rem 0rem 0.5rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement p {
  color: #fff;
  background-color: #2d324a;
  -webkit-box-flex: 0.8;
      -ms-flex: 0.8;
          flex: 0.8;
  padding-left: 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement div, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement div, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement div {
  -webkit-box-flex: 0.2;
      -ms-flex: 0.2;
          flex: 0.2;
  margin-left: 0.5rem;
  background-color: #2d324a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.85vh 0;
  border-radius: 0 0.5rem 0.5rem 0;
  font-weight: 800;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement div svg, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement div svg, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement div svg {
  color: #fff;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement--dropdown, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement--dropdown, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement--dropdown {
  position: absolute;
  right: 0;
  -webkit-box-flex: 0.2;
      -ms-flex: 0.2;
          flex: 0.2;
  cursor: pointer;
  border-radius: 0 1rem 1rem 0;
  width: 7vh;
  background-color: #2d324a;
  overflow-y: auto;
  z-index: 1;
  scrollbar-width: 5px;
  scrollbar-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  margin: 0;
  color: #fff;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement--dropdown::-webkit-scrollbar, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement--dropdown::-webkit-scrollbar, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement--dropdown::-webkit-scrollbar {
  width: 5px;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement--dropdown::-webkit-scrollbar-track, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement--dropdown::-webkit-scrollbar-track, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement--dropdown::-webkit-scrollbar-track {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%, white), to(rgba(63, 15, 64, 0.4)));
  background: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement--dropdown::-webkit-scrollbar-thumb, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement--dropdown::-webkit-scrollbar-thumb, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement--dropdown::-webkit-scrollbar-thumb {
  background-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  border: none;
  outline: none;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement--dropdown select, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement--dropdown select, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement--dropdown select {
  color: #fff;
  scrollbar-width: 5px;
  scrollbar-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  margin: 0;
  font-size: 1.4rem;
  font-weight: 700;
  border: none;
  width: 2vh;
  border-radius: 0 1rem 1rem 0;
  margin: 0;
  background-color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement--dropdown select::-webkit-scrollbar, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement--dropdown select::-webkit-scrollbar, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement--dropdown select::-webkit-scrollbar {
  width: 5px;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement--dropdown select::-webkit-scrollbar-track, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement--dropdown select::-webkit-scrollbar-track, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement--dropdown select::-webkit-scrollbar-track {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%, white), to(rgba(63, 15, 64, 0.4)));
  background: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement--dropdown select::-webkit-scrollbar-thumb, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement--dropdown select::-webkit-scrollbar-thumb, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement--dropdown select::-webkit-scrollbar-thumb {
  background-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  border: none;
  outline: none;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement--dropdown select:focus, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement--dropdown select:focus, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement--dropdown select:focus {
  outline: none;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement:nth-child(1), .user-profile-section__container--main__right-side--main-content .profile-settings__account--info .date-of-birth__elemement:nth-child(2), .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement:nth-child(1), .user-profile-section__container--main__right-side--main-content .profile-settings__skills--info .date-of-birth__elemement:nth-child(2), .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement:nth-child(1), .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__elemement:nth-child(2) {
  margin-right: 1.5rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account .skill-evidence, .user-profile-section__container--main__right-side--main-content .profile-settings__skills .skill-evidence, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview .skill-evidence {
  all: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100vw;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account .skill-evidence p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills .skill-evidence p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview .skill-evidence p {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account .skill-evidence .past-exprience-evidence__image-section, .user-profile-section__container--main__right-side--main-content .profile-settings__skills .skill-evidence .past-exprience-evidence__image-section, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview .skill-evidence .past-exprience-evidence__image-section {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account .skill-evidence .past-exprience-evidence__image-section--image-container, .user-profile-section__container--main__right-side--main-content .profile-settings__skills .skill-evidence .past-exprience-evidence__image-section--image-container, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview .skill-evidence .past-exprience-evidence__image-section--image-container {
  position: relative;
  height: 130px;
  width: 180px;
  margin-bottom: 1.5vh;
  margin-right: 1vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account .skill-evidence .past-exprience-evidence__image-section--image-container img, .user-profile-section__container--main__right-side--main-content .profile-settings__skills .skill-evidence .past-exprience-evidence__image-section--image-container img, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview .skill-evidence .past-exprience-evidence__image-section--image-container img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account .skill-evidence .past-exprience-evidence__image-section--image-container__delete-button, .user-profile-section__container--main__right-side--main-content .profile-settings__skills .skill-evidence .past-exprience-evidence__image-section--image-container__delete-button, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview .skill-evidence .past-exprience-evidence__image-section--image-container__delete-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #2d324a;
  opacity: 0.9;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 900;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account .skill-evidence .past-exprience-evidence__image-section--image-container__delete-button svg, .user-profile-section__container--main__right-side--main-content .profile-settings__skills .skill-evidence .past-exprience-evidence__image-section--image-container__delete-button svg, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview .skill-evidence .past-exprience-evidence__image-section--image-container__delete-button svg {
  color: #fff;
  height: 2rem;
  width: 2rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account .skill-evidence .past-exprience-evidence__upload, .user-profile-section__container--main__right-side--main-content .profile-settings__skills .skill-evidence .past-exprience-evidence__upload, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview .skill-evidence .past-exprience-evidence__upload {
  width: 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  margin-left: 2vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account .skill-evidence .past-exprience-evidence__upload--file, .user-profile-section__container--main__right-side--main-content .profile-settings__skills .skill-evidence .past-exprience-evidence__upload--file, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview .skill-evidence .past-exprience-evidence__upload--file {
  background-color: red;
  color: #fff;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--additional-skills, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--additional-skills, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--additional-skills {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--additional-skills p, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--additional-skills p, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--additional-skills p {
  font-size: 1.5rem;
  font-weight: 700;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--additional-skills__picker-container, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--additional-skills__picker-container, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--additional-skills__picker-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--additional-skills__picker-container--picker, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--additional-skills__picker-container--picker, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--additional-skills__picker-container--picker {
  width: calc(100% / 3.5);
  border: dotted 2px #2d324a;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1.5vh;
  margin-top: 1.5vh;
  height: 6vh;
  font-size: 2rem;
  margin-right: 3vh;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--additional-skills__picker-container select, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--additional-skills__picker-container select, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--additional-skills__picker-container select {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 6vh;
  border: none;
  background-color: #2d324a;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 800;
  padding: 1vh 1.5vh;
  cursor: pointer;
  overflow: auto;
  scrollbar-width: 5px;
  scrollbar-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  margin: 0;
  border-radius: 5px;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--additional-skills__picker-container select::-webkit-scrollbar, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--additional-skills__picker-container select::-webkit-scrollbar, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--additional-skills__picker-container select::-webkit-scrollbar {
  width: 5px;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--additional-skills__picker-container select::-webkit-scrollbar-track, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--additional-skills__picker-container select::-webkit-scrollbar-track, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--additional-skills__picker-container select::-webkit-scrollbar-track {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%, white), to(rgba(63, 15, 64, 0.4)));
  background: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--additional-skills__picker-container select::-webkit-scrollbar-thumb, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--additional-skills__picker-container select::-webkit-scrollbar-thumb, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--additional-skills__picker-container select::-webkit-scrollbar-thumb {
  background-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  border: none;
  outline: none;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--additional-skills__picker-container select:focus, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--additional-skills__picker-container select:focus, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--additional-skills__picker-container select:focus {
  outline: none;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--additional-skills__list, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--additional-skills__list, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--additional-skills__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--additional-skills__list--item, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--additional-skills__list--item, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--additional-skills__list--item {
  width: 100%;
  margin-right: 2vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.6rem;
  border: 1px solid #2d324a;
  padding: 0.5vh 2vh;
  color: #fff;
  background-color: #2d324a;
  border-radius: 5px;
}

.user-profile-section__container--main__right-side--main-content .profile-settings__account--additional-skills__list--item svg, .user-profile-section__container--main__right-side--main-content .profile-settings__skills--additional-skills__list--item svg, .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--additional-skills__list--item svg {
  margin-left: 1vh;
  cursor: pointer;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--main-content .profile-settings__header--title {
    font-size: 2rem;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    margin-top: 2vh;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__tabs p {
    padding: 1vh;
    font-size: 1.3rem;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__tabs--active-tab {
    border: 1px solid #2d324a;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    margin-top: 2vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0 2vh 0 0;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info p {
    all: unset;
    font-size: 1.6rem;
    margin-bottom: 1vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info p:nth-child(1) {
    width: 100%;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info div {
    margin-bottom: 1vh;
    width: 100%;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .details-capture {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
    margin: 1vh;
    padding: 0 2vh 0 0;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .details-capture input {
    width: 100%;
    margin: 1vh 0;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
    margin: 1vh 0;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__element {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__element--dropdown {
    all: unset;
    background-color: transparent;
  }
  .user-profile-section__container--main__right-side--main-content .profile-settings__user-profile-overview--info .date-of-birth__element--dropdown select {
    all: unset;
    height: 20vh;
  }
}

.user-profile-section__container--main__right-side--main-content .notifications {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content .notifications__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.user-profile-section__container--main__right-side--main-content .notifications__underline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 3px;
  background-color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content .notifications__no-notifications {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  height: 80vh;
}

.user-profile-section__container--main__right-side--main-content .notifications__entries-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 80vh;
  overflow-y: auto;
  scrollbar-width: 5px;
  scrollbar-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  margin: 0;
}

.user-profile-section__container--main__right-side--main-content .notifications__entries-container::-webkit-scrollbar {
  width: 5px;
}

.user-profile-section__container--main__right-side--main-content .notifications__entries-container::-webkit-scrollbar-track {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%, white), to(rgba(63, 15, 64, 0.4)));
  background: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
}

.user-profile-section__container--main__right-side--main-content .notifications__entries-container::-webkit-scrollbar-thumb {
  background-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  border: none;
  outline: none;
}

.user-profile-section__container--main__right-side--main-content .notifications__entries-container--entry {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2fr 1fr 9.2fr 0.6fr 0.2fr;
      grid-template-columns: 2fr 1fr 9.2fr 0.6fr 0.2fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  padding: 0.5vh 1vh 0.5vh 2vh;
  width: 100%;
  cursor: pointer;
  margin: 1vh 0;
}

.user-profile-section__container--main__right-side--main-content .notifications__entries-container--entry p {
  font-size: 1.4rem;
  font-weight: 800;
  color: #2d324a;
}

.user-profile-section__container--main__right-side--main-content .notifications__entries-container--entry p:last-child {
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
}

.user-profile-section__container--main__right-side--main-content .notifications .order-entry {
  background-color: #e7f5aa;
}

.user-profile-section__container--main__right-side--main-content .notifications .gig-entry {
  background-color: #c7c9c0;
}

.user-profile-section__container--main__right-side--help {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 20vh;
  height: 7vh;
  opacity: 0.8;
  position: absolute;
  bottom: 10vh;
  right: 0;
  background-color: #c4c4c4;
  border: 1px solid #2d324a;
  border-radius: 8px 0 0 8px;
  cursor: pointer;
}

.user-profile-section__container--main__right-side--help p {
  color: #2d324a;
  font-weight: 800;
  font-size: 1.5rem;
}

.user-profile-section__container--main__right-side--user-details-btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #2d324a;
  width: calc(100% / 1);
  height: 10vh;
  position: absolute;
  bottom: 0;
  left: 0.5vh;
  z-index: 12;
  opacity: 0.8;
}

.user-profile-section__container--main__right-side--user-details-btn-container button {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  border: none;
  background-color: #c4c4c4;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  padding: 2vh 3vh;
  margin-left: 5vh;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.user-profile-section__container--main__right-side--user-details-btn-container button:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.user-profile-section__container--main__right-side--user-details-btn-container button:nth-child(2) {
  background-color: #2d324a;
  color: #c4c4c4;
  border: 1px solid #c4c4c4;
}

.user-profile-section__container--main__right-side--footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 6vh;
  background-color: #f8d12c;
  color: #2d324a;
  position: absolute;
  bottom: 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.user-profile-section__container--main__right-side--footer p {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.user-profile-section__container--main__right-side--footer p svg {
  margin-right: 1vh;
}

@media all and (max-width: 991px) {
  .user-profile-section__container--main__right-side--footer {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.chat-container {
  position: absolute;
  bottom: 3vh;
  right: 15vh;
  width: 25vw;
  height: 70vh;
  border: 2px solid #2d324a;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.chat-container__header {
  width: 100%;
  height: 6vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #2d324a;
  color: white;
  font-size: 2.5rem;
  font-weight: 800;
  padding: 1vh 2vh;
}

.chat-container__header--left-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.chat-container__header--left-side__avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.chat-container__header--left-side__avatar img {
  width: 4vh;
  height: 4vh;
  border-radius: 50%;
}

.chat-container__header--left-side__name {
  font-weight: 800;
}

.chat-container__header--left-side__name h2 {
  font-size: 1.5rem;
  font-weight: 800;
  margin-left: 1rem;
}

.chat-container__header--right-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.chat-container__header--right-side__close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.chat-container__header--right-side__close p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2.5rem;
  font-weight: 900;
  margin-left: 1rem;
  color: #fff;
}

.chat-container__content {
  width: 100%;
  height: 86vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.chat-container__content--messages {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow-y: auto;
  scrollbar-width: 5px;
  scrollbar-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  margin: 0;
  overflow-x: hidden;
}

.chat-container__content--messages::-webkit-scrollbar {
  width: 5px;
}

.chat-container__content--messages::-webkit-scrollbar-track {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(35%, white), to(rgba(63, 15, 64, 0.4)));
  background: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
}

.chat-container__content--messages::-webkit-scrollbar-thumb {
  background-color: linear-gradient(white 35%, rgba(63, 15, 64, 0.4) 100%);
  border: none;
  outline: none;
}

.chat-container__content--messages__message {
  width: 100%;
  margin: 1vh 0 1vh 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.chat-container__content--messages__message p {
  font-size: 1.2rem;
  font-weight: 800;
  margin-left: 1rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 60%;
  padding: 1vh 2vh;
  border-radius: 0 5px 5px 5px;
  background-color: #e7f5aa;
}

.chat-container__content--messages .outgoing-text {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.chat-container__content--messages .outgoing-text p {
  background-color: #4085ee;
  border-radius: 5px 0 5px 5px;
  margin-right: 2vh;
}

.chat-container__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 8vh;
  background-color: #2d324a;
  padding: 1vh;
}

.chat-container__footer--content {
  width: 98%;
  height: 6vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
  background-color: #fff;
  font-size: 1.5rem;
  font-weight: 800;
  border-radius: 1vh;
  padding: 0 1vh;
}

.chat-container__footer--content__input {
  -webkit-box-flex: 0.8;
      -ms-flex: 0.8;
          flex: 0.8;
}

.chat-container__footer--content__input input {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: none;
  color: #2d324a;
  font-size: 1.2rem;
  font-weight: 800;
  outline: none;
}

.chat-container__footer--content__input input:focus {
  outline: none;
}

.chat-container__footer--content__send {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0.2;
      -ms-flex: 0.2;
          flex: 0.2;
  height: 6vh;
}

.chat-container__footer--content__send button {
  color: #2d324a;
  font-size: 1.5rem;
  font-weight: 800;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  font-style: normal;
}

.gigwiz-footer {
  height: 450px;
  background-color: #585e7b;
  color: #fff;
  font-size: 1.8rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.gigwiz-footer__content {
  height: 80%;
  background-color: #2d324a;
  padding: 20px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}

.gigwiz-footer__content .footer-entry {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
}

.gigwiz-footer__content .footer-entry__description {
  margin-top: 50px;
}

.gigwiz-footer__content .footer-entry__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 10px;
  color: #f8d12c;
}

.gigwiz-footer__content .footer-entry__social .icon {
  margin-right: 15px;
  font-size: 2rem;
  cursor: pointer;
}

.gigwiz-footer__content .footer-entry .text-input {
  width: 240px;
  margin-top: 10px;
  height: 28px;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: #c4c4c4;
  padding-left: 10px;
  font-weight: 600;
  color: #2d324a;
}

.gigwiz-footer__content .footer-entry .message {
  height: 70px;
  font-size: 1.5rem;
  padding-top: 10px;
  resize: none;
}

.gigwiz-footer__content .footer-entry .submit-btn {
  background-color: #f8d12c;
  color: #2d324a;
  padding: 5px 15px;
  font-weight: 800;
  border: none;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
}

.gigwiz-footer__content .footer-entry__link {
  cursor: pointer;
}

.gigwiz-footer__copyright {
  background-color: #f8d12c;
  width: 100%;
  height: 40px;
  color: #2d324a;
  font-size: 1.3rem;
  font-weight: 900;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
}

.gigwiz-footer__copyright--icon {
  font-size: 1.5rem;
}

.gigwiz-footer__copyright > p {
  margin-left: 5px;
}

@media all and (max-width: 991px) {
  .gigwiz-footer {
    height: auto;
    background-color: #2d324a;
    font-size: 1.5rem;
  }
  .gigwiz-footer form {
    display: none;
  }
  .gigwiz-footer__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    height: 100%;
    padding: 10px 20px 35px 20px;
  }
  .gigwiz-footer__content .footer-entry {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .gigwiz-footer__content .footer-entry__description {
    margin-top: 15px;
  }
  .gigwiz-footer__content .footer-entry__social {
    position: absolute;
    bottom: 10px;
    margin-left: 100px;
  }
  .gigwiz-footer__content .footer-entry:nth-child(1) {
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .gigwiz-footer__content .footer-entry:nth-child(2) {
    margin-top: 5px;
    width: 100%;
  }
  .gigwiz-footer__content .footer-entry:nth-child(2) p:nth-child(1) {
    margin-bottom: 5px;
  }
  .gigwiz-footer__content .footer-entry:nth-child(3) {
    margin-top: 15px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .gigwiz-footer__content .footer-entry:nth-child(3):active form {
    display: block;
  }
}
